<template>

    <!--<h4 class="not-mobile">
      Template is built for mobile, for best experience please use a mobile device or use the browser in mobile mode.
    </h4>-->
    <ion-app>
    <ion-router-outlet />
  </ion-app>
</template>

<script >
import {  IonRouterOutlet } from '@ionic/vue';
import { defineComponent } from 'vue';
import { IonApp } from '@ionic/vue';
export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet
  }
});
</script>
<style type="text/css">
  
   ion-content {
  --padding-start: 0113px;
  --padding-end: 8px;
  --padding-top: 20px;
  --padding-bottom: 20px;
}





</style>
