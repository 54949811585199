import { createRouter, createWebHistory } from '@ionic/vue-router';

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/register',
    component: () => import('@/views/Register.vue')
  },
  {
    name: 'chat',
    path: '/chat/:chat_id',
    meta: {title : 'Chat'},
    component: () => import('@/views/chat/ChatMensajes.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('@/views/Menu.vue'),
    children: [ 
      {
        path: '/colaboradores',
        meta: {title : 'Oficinas'},
        component: () => import('@/views/colaboradores/colaboradores.vue')
      },
      {
        path: '/escritorio',
        meta: {title : 'Escritorio'},
        component: () => import('@/views/escritorio/index.vue')
      },
      {
        path: '/clientes',
        meta: {title : 'Clientes'},
        component: () => import('@/views/clientes/clientes.vue')
      },
      {
        path: '/clinicas',
        meta: {title : 'Clinicas'},
        component: () => import('@/views/clinicas/clinica.vue')
      },
      {
        path: '/clientes/:id',
        name: 'cliente_coloborador',
        meta: {title : 'Clientes'},
        component: () => import('@/views/clientes/clientesPorColaborador.vue')
      },
      {
        path: '/asignar-rol',
        meta: {title : 'Asignar Rol'},
        component: () => import('@/views/asignar-rol/asignaciones.vue')
      },
      {
        path: '/administradores',
        meta: {title : 'Administradores'},
        component: () => import('@/views/administradores/administradores.vue')
      },
      {
        path: '/sub-administradores',
        meta: {title : 'Despacho'},
        component: () => import('@/views/subadministradores/subadministradores.vue')
      },
      {
        path: '/siniestros',
        name: 'list-siniestros', 
        meta: {title : 'Siniestros'},
        component: () => import('@/views/siniestros/siniestros.vue')
      },
      {
        path: '/documentos/:siniestro_id',
        name: 'documentos',
        meta: {title : 'Documentos'},
        component: () => import('@/views/area-documentos/areaDocumentos.vue')
      },
      {
        path: '/documentos/:siniestro_id/:documento_id/:user_id',
        name: 'documentos_siniestro',
        meta: {title : 'Firma de Documentos'},
        component: () => import('@/views/area-documentos/firmaDocumentosCliente.vue')
      },
      {
        path: '/documentos',
        meta: {title : 'Documentos'},
        component: () => import('@/views/area-documentos/areaDocumentos.vue')
      },
      {
        path: '/firmar/:documento_id',
        meta: {title : 'Firmar Documentos'},
        name: 'signature-document',
        component: () => import('@/views/area-documentos/firmaDocumentov2.vue')
      },
      {
        path: '/agregar-documento/:siniestro_id',
        meta: {title : 'Agregar Documento'},
        name:'add-document',
        component: () => import('@/views/area-documentos/agregarDocumento.vue')
      },
      {
        name : 'notas',
        path: '/notas/:id_siniestro',
        meta: {title : 'Notas'},
        component: () => import('@/views/notas/notas.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router